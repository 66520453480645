<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-switch
      :v-model="element.model"
      :label="element.label"
      color="red darken-3"
      hide-details
    />
  </v-col>
</template>
<script>

export default {
  name: 'SwitchRenderer',
  components: {},
  props: ['element'],
  data() {
    return {};
  },
  computed: {
  },
  mounted() {},
  methods: {},
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
