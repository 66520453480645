<template>
  <v-row
    :no-gutters="element['no-gutters'] ? true : false"
  >
    <template v-if="element.elements">
      <element-renderer
        v-for="item in element.elements"
        :key="item.id"
        :element="item"
      />
    </template>
  </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'RowRenderer',
  components: {
    ElementRenderer: () => import('./element-renderer.vue'),
  },
  props: ['element'],
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      sortList: (state) => state.ticket.sortList,
    }),
  },
  mounted() {

  },
  methods: {
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
