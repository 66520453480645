import axios from 'axios';
import { API_URL } from '@/config';
// import { getToken } from './auth';

axios.defaults.baseURL = API_URL;
// axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;

const searchPayment = (params) => axios.get('/v1/payment/record/search', { params });
const searchFile = (params) => axios.get('/v1/payment/file/search', { params });

const businessUnit = (params) => axios.get('/inbox/v1/user/businessunit', { params });

export {
  searchPayment,
  searchFile,
  businessUnit,
  axios,
};
