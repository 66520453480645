<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <template v-if="element.elements">
      <element-renderer
        v-for="item in element.elements"
        :key="item.id"
        :element="item"
      />
    </template>
  </v-col>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'ColumnRenderer',
  components: {
    ElementRenderer: () => import('./element-renderer.vue'),
  },
  props: ['max-width', 'element'],
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      sortList: (state) => state.ticket.sortList,
    }),
  },
  mounted() {
    console.log('column');
  },
  methods: {
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
