<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-textarea
      :label="element.label ? element.label : ''"
      :hint="element.hint ? element.hint : ''"
      :placeholder="element.placeholder ? element.placeholder : null"
      :v-model="element.model"
      :value="element.model"
      :rules="element.required ? rules.required : []"
      @change="changed"
    />
  </v-col>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import rules from '../../lib/rules.lib';

export default {
  name: 'TextareaRenderer',
  components: {},
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      rules,
      model: '',
    };
  },
  computed: {
    ...mapState({
      sortList: (state) => state.ticket.sortList,
    }),
  },
  mounted() {},
  methods: {
    changed($event) {
      const params = {
        data: $event,
        element: this.element,
      };
      this.$emit('change', params);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
