<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
    @click="clicked"
  >
    <v-autocomplete
      v-model="model"
      :items="localElement.source.items"
      :label="element.label ? element.label : ''"
      :placeholder="element.placeholder ? element.placeholder : null"
      :search-input.sync="search"
      :loading="isLoading"
      :item-text="element.itemText"
      :item-value="element.itemValue"
      :clearable="true"
      :chips="element.chips ? element.chips : null"
      :multiple="element.multiple ? element.multiple : false"
      return-object
      :rules="element.required ? rules.required : []"
      :deletable-chips="true"
      @change="changed"
    />
  </v-col>
</template>
<script>
// import { mapState } from 'vuex';
// import format from 'string-template';
import Mustache from 'mustache';
import { mapState } from 'vuex';
import { axios } from '../../services/api';
import rules from '../../lib/rules.lib';

const distinct = (array, valueKey) => {
  const result = [];
  const map = new Map();
  array.forEach((item) => {
    if (!map.has(item[valueKey])) {
      map.set(item[valueKey], true); // set any value to Map
      result.push(item);
    }
  });
  return result;
};

export default {
  name: 'AutocompleteRenderer',
  components: {},
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      rules,
      model: null,
      items: [],
      localElement: {
        source: {
          items: [],
        },
      },
      clickCount: 0,
      isLoading: false,
      search: null,
      valueKey: 'value',
      textKey: 'text',
    };
  },
  computed: {
    ...mapState({
      formData: (state) => state.ticket.formData,
    }),
  },
  watch: {
    search(query) {
      // const axios.get('')
      this._search(query);
    },
  },
  mounted() {
    this.localElement = { ...this.element };
    this.setTextAndValueKeys();
    // console.log('renderer 2 this.element.model', this.element.model);
    if (this.element && this.element.model) {
      // console.log('this.element.model', this.element.model);
      // this.model = this.element.model.value;
      this.setDefaultValue();
    } else {
      this.items = [];
    }

    // alert('mounted');
    // this._search('');
  },
  methods: {
    setTextAndValueKeys() {
      this.valueKey = this.element.itemValue ? this.element.itemValue : 'value';
      this.textKey = this.element.itemText ? this.element.itemText : 'text';
    },
    clicked() {
      this.clickCount++;
      if (!this.items || !this.items.length) {
        this._search('');
      }
    },
    changed($event) {
      // console.log('$event', $event);
      const items = $event;
      // console.log('this.items createItems items', $event);
      // console.log('this.items createItems items', items);
      this.items = items;
      let data = null;
      if ($event) {
        data = this.getData($event);
        // this.model = this.getModelValue(data);
      } else {
        // this.model = null;
      }
      const params = {
        data,
        element: this.element,
      };
      // console.log('data', data);
      this.$emit('change', params);
    },
    getModelValue(data) {
      try {
        if (Array.isArray(data)) {
          return data.map((item) => item.value);
        }
        return data.value;
      } catch (error) {
        console.log('error', error);
      }
      return null;
    },
    getData($event) {
      return this.element.multiple ? this.getDataArray($event) : this.getDataObject($event);
    },
    getDataObject($event) {
      const data = {};
      try {
        const valueKey = this.element.itemValue && $event[this.element.itemValue] ? this.element.itemValue : 'value';
        const textKey = this.element.itemText && $event[this.element.itemText] ? this.element.itemText : 'text';
        // data[valueKey] = $event[valueKey];
        // data[textKey] = $event[textKey];
        data.value = $event[valueKey];
        data.text = $event[textKey];
        return data;
      } catch (error) {
        //
      }
      return data;
    },
    getDataArray($event) {
      const result = [];
      $event.forEach((item) => {
        const row = this.getDataObject(item);
        if (row) {
          result.push(row);
        }
      });
      return result;
    },
    isArrayValue() {

    },
    createItems(items) {
      const result = [];
      const models = this.element.multiple ? [...items] : [items];
      const itemValue = this.element.itemValue ? this.element.itemValue : 'value';
      const itemText = this.element.itemText ? this.element.itemText : 'text';

      if (models && Array.isArray(models)) {
        models.forEach((row) => {
          try {
            if (itemValue && itemText && row && row.text) {
              const item = {};
              item[itemValue] = row.value;
              item[itemText] = row.text;
              result.push(item);
            }
          } catch (error) {
            // error
            console.log(error);
          }
        });
      }

      let returnResult = result;
      if (!this.element.multiple) {
        returnResult = result && result.length ? result[0] : null;
      }
      return returnResult;
    },
    setDefaultValue() {
      if (this.element.source.type !== 'api') {
        // return;
      }
      const items = this.element.source.type === 'list' ? this.element.source.items : [];
      const models = this.element.multiple ? this.element.model : [this.element.model];
      // const { itemValue, itemText } = this.element;
      const itemValue = this.element.itemValue ? this.element.itemValue : 'value';
      const itemText = this.element.itemText ? this.element.itemText : 'text';
      const model = [];
      // console.log('models', models);
      // console.log('itemValue', itemValue);
      // console.log('itemText', itemText);

      if (models && Array.isArray(models)) {
        models.forEach((row) => {
          try {
            if (itemValue && itemText && row && row.text) {
              const item = {};
              item[itemValue] = row.value;
              item[itemText] = row.text;
              items.push(item);
              model.push(row.value);
            }
          } catch (error) {
            // error
            console.log(error);
          }
        });
      }
      let localModel = [...items];
      if (!this.element.multiple) {
        localModel = items && items.length ? items[0] : null;
      }
      this.model = localModel;
      // const distinctItems = distinct(items, this.valueKey);
      // console.log('localModel', localModel);
      this.items = [...items];
      this.localElement.source.items = [...items];
    },
    _search(query) {
      if (query == null || this.element.source.type !== 'api') {
        return;
      }
      // console.log('_search query', query);
      if (!query && this.element.source.type !== 'api') {
        // console.log('setDefaultValue');
        this.setDefaultValue();
        return;
      }
      const items = this.element.multiple ? this.items : [];
      // console.log('this.items', this.items);
      // console.log('items', items);
      // const items = [];
      // const axios.get('')
      // const lParams = { query };

      const view = { query };
      view.formData = this.formData;
      console.log('view', view);

      const url = Mustache.render(this.element.source.url, view);

      // const url = this.element.source.url.replace('{query}', query);

      this.isLoading = true;

      axios.get(url)
        .then((response) => {
          // console.log('response', response);
          const data = response.data;
          if (Array.isArray(data)) {
            this.localElement.source.items = distinct([...items, ...data], this.valueKey);
          } else if (data.results) {
            this.localElement.source.items = distinct([...items, ...data.results], this.valueKey);
          } else if (data._embedded && data._embedded.item) {
            this.localElement.source.items = distinct([...items, ...data._embedded.item], this.valueKey);
          } else {
            this.localElement.source.items = distinct([...items], this.valueKey);
          }
          // console.log('this.localElement.source.items', this.localElement.source.items);
          // this.items = res
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
