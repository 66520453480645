<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.md ? element.md : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    {{ element.label ? element.label : "" }} :
    <date-picker
      v-model="dateTime"
      format="DD-MM-YYYY hh:mm"
      :minute-step="15"
      type="datetime"
      style="width: 100%; margin-top: 2px"
      @change="changed"
    />
    <!-- <v-text-field
      v-model="model"
      :label="element.label ? element.label : ''"
      :type="element.numeric ? 'number' : 'text'"
      :placeholder="element.placeholder ? element.placeholder : null"
      :rules="element.required ? rules.required : []"
      @change="changed"
    /> -->
  </v-col>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import rules from '../../lib/rules.lib';

export default {
  name: 'AppDateTimePicker',
  components: {
    DatePicker,
  },
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      dateTime: null,
      rules,
      model: null,
      data: {},
    };
  },
  computed: {},
  mounted() {
    // none
    try {
      // console.log(this.element.model);
      if (this.element.model) {
        this.dateTime = moment(this.element.model).toDate();
      } else if (this.element.defaultNow) {
        this.dateTime = moment().toDate();
      }
      // this.model = this.element.model ? this.element.model : null;
      // const key = this.form.key;
      // this.data = this.ticket.content[key];
    } catch (error) {
      this.data = {};
    }
  },
  methods: {
    changed($event) {
      alert($event);
      const params = {
        data: $event,
        element: this.element,
      };
      this.$emit('change', params);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
