<template>
  <fragment>
    <row-renderer
      v-if="element && element.type && element.type === 'row'"
      :element="element"
      @change="changed"
    />
    <column-renderer
      v-if="element && element.type && element.type === 'column'"
      :element="element"
    />
    <container-renderer
      v-if="element && element.type && element.type == 'container'"
      :element="element"
    />
    <text-field-renderer
      v-if="element && element.type && element.type == 'text-field'"
      :element="element"
      @change="changed"
    />
    <date-time-text-field-renderer
      v-if="element && element.type && element.type == 'date-time-text-field'"
      :element="element"
      @change="changed"
    />
    <textarea-renderer
      v-if="element && element.type && element.type == 'textarea'"
      :element="element"
      @change="changed"
    />
    <autocomplete-renderer
      v-if="element && element.type && element.type == 'autocomplete'"
      :element="element"
      @change="changed"
    />
    <switch-renderer
      v-if="element && element.type && element.type == 'switch'"
      :element="element"
      @change="changed"
    />
    <file-renderer
      v-if="element && element.type && element.type == 'file'"
      :element="element"
      @change="changed"
    />
    <date-picker-renderer
      v-if="element && element.type && element.type == 'date-picker'"
      :element="element"
      @change="changed"
    />
    <radio-field-renderer
      v-if="element && element.type && (element.type == 'radio-field' || element.type == 'radio')"
      :element="element"
      @change="changed"
    />
    <date-time-picker-renderer
      v-if="element && element.type && (element.type == 'date-time-picker')"
      :element="element"
      @change="changed"
    />
  </fragment>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import RowRenderer from './row-renderer.vue';
import ColumnRenderer from './column-renderer.vue';
import ContainerRenderer from './container-renderer.vue';
import TextFieldRenderer from './text-field-renderer.vue';
import DateTimeTextFieldRenderer from './date-time-text-field-renderer.vue';
import TextareaRenderer from './textarea-renderer.vue';
import AutocompleteRenderer from './autocomplete-renderer.vue';
import SwitchRenderer from './switch-renderer.vue';
import FileRenderer from './file-renderer.vue';
import DatePickerRenderer from './date-picker-renderer.vue';
import RadioFieldRenderer from './radio-field-renderer.vue';
import DateTimePickerRenderer from './date-time-picker-renderer.vue';
import { getElementValue } from '../../lib/renderer.lib';

// switch
export default {
  name: 'ElementRenderer',
  components: {
    'row-renderer': RowRenderer,
    'column-renderer': ColumnRenderer,
    'container-renderer': ContainerRenderer,
    'text-field-renderer': TextFieldRenderer,
    'textarea-renderer': TextareaRenderer,
    'autocomplete-renderer': AutocompleteRenderer,
    'switch-renderer': SwitchRenderer,
    'file-renderer': FileRenderer,
    'date-picker-renderer': DatePickerRenderer,
    'date-time-text-field-renderer': DateTimeTextFieldRenderer,
    'radio-field-renderer': RadioFieldRenderer,
    'date-time-picker-renderer': DateTimePickerRenderer,
  },
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      localElement: null,
    };
  },
  computed: {
    ...mapState({
      sortList: (state) => state.ticket.sortList,
      formData: (state) => state.ticket.formData,
    }),
  },
  mounted() {
    // console.log('this.formData', this.formData);
    // console.log('renderer 1 this.element', this.element.model);
    // this.element = { ...this.element };
    // const model = getElementValue(this.formData, this.element);
    // console.log('model', model);
    // console.log('element', this.element);
  },
  methods: {
    changed(message) {
      this.$store.commit('UPDATE_FORM_DATA', message);
      // console.log('message', message);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
