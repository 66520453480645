<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-menu
      v-model="localElement.menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          :label="element.label"
          prepend-icon="mdi-calendar"
          readonly
          clearable
          v-bind="attrs"
          v-on="on"
          @change="changed"
        />
      </template>
      <v-date-picker
        v-model="model"
        @input="localElement.menu = false"
        @change="changed"
      />
    </v-menu>
  </v-col>
</template>
<script>

export default {
  name: 'FileRenderer',
  components: {},
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      localElement: {
        menu: false,
      },
      model: null,
    };
  },
  computed: {
  },
  methods: {
    changed($event) {
      const params = {
        data: $event,
        element: this.element,
      };
      this.$emit('change', params);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
