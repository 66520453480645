<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.md ? element.md : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-radio-group
      v-model="model"
      row
      @change="changed"
    >
      <template v-for="item in items">
        <v-radio
          :key="item.value"
          :label="item.text"
          :value="item.value"
        />
      </template>
    </v-radio-group>
  </v-col>
</template>
<script>
import rules from '../../lib/rules.lib';

export default {
  name: 'RadioFieldRenderer',
  components: {},
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      rules,
      model: null,
      items: [],
      data: {},
    };
  },
  computed: {
  },
  mounted() {
    // none
    try {
      this.setItems();
      this.model = this.element.model ? this.element.model : null;
      // const key = this.form.key;
      // this.data = this.ticket.content[key];
    } catch (error) {
      this.data = {};
    }
  },
  methods: {
    setItems() {
      try {
        this.items = this.element.source.items;
      } catch (error) {
        // error
      }
    },
    changed($event) {
      const params = {
        data: $event,
        element: this.element,
      };
      this.$emit('change', params);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
