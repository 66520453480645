<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.md ? element.md : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-text-field
      v-model="model"
      :label="element.label ? element.label : ''"
      :type="element.numeric ? 'number' : 'text'"
      :placeholder="element.placeholder ? element.placeholder : null"
      :rules="element.required ? rules.required : []"
      @change="changed"
    />
  </v-col>
</template>
<script>
import rules from '../../lib/rules.lib';

export default {
  name: 'TextFieldRenderer',
  components: {},
  props: {
    element: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      rules,
      model: null,
      data: {},
    };
  },
  computed: {
  },
  mounted() {
    // none
    try {
      this.model = this.element.model ? this.element.model : null;
      // const key = this.form.key;
      // this.data = this.ticket.content[key];
    } catch (error) {
      this.data = {};
    }
  },
  methods: {
    changed($event) {
      const params = {
        data: $event,
        element: this.element,
      };
      this.$emit('change', params);
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
