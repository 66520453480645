<template>
  <v-col
    :cols="element.cols ? element.xs : 12"
    :xs="element.xs ? element.xs : null"
    :md="element.xs ? element.xs : null"
    :lg="element.lg ? element.lg : null"
    :class="element.class ? element.class : null"
  >
    <v-file-input
      :v-model="element.model"
      :label="element.label"
      multiple
      prepend-icon="mdi-paperclip"
    >
      <template #selection="{ text }">
        <v-chip
          small
          label
          color="primary"
        >
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
  </v-col>
</template>
<script>

export default {
  name: 'FileRenderer',
  components: {},
  props: ['element'],
  data() {
    return {};
  },
  computed: {
  },
  mounted() {},
  methods: {},
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
